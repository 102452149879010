<script>
import SecondaryButton from "@/components/SecondaryButton.vue";
export default {
	components: { SecondaryButton },
	props: {
		maxVisibleButtons: {
			type: Number,
			required: false,
			default: 3,
		},
		totalPages: {
			type: Number,
			required: true,
		},
		total: {
			type: Number,
			required: true,
		},
		perPage: {
			type: Number,
			required: true,
		},
		currentPage: {
			type: Number,
			required: true,
		},
	},
	computed: {
		startPage() {
			if (this.currentPage === 1) {
				return 1;
			}

			if (this.currentPage === this.totalPages) {
				return this.totalPages - this.maxVisibleButtons + 1;
			}

			return this.currentPage - 1;
		},
		endPage() {
			return Math.min(
				this.startPage + this.maxVisibleButtons - 1,
				this.totalPages
			);
		},
		pages() {
			const range = [];

			for (let i = this.startPage; i <= this.endPage; i += 1) {
				range.push({
					name: i,
					isDisabled: i === this.currentPage,
				});
			}

			return range;
		},
		isInFirstPage() {
			return this.currentPage === 1;
		},
		isInLastPage() {
			return this.currentPage === this.totalPages;
		},
	},
	methods: {
		onClickFirstPage() {
			this.$emit("pagechanged", 1);
		},
		onClickPreviousPage() {
			this.$emit("pagechanged", this.currentPage - 1);
		},
		onClickPage(page) {
			if (page != this.currentPage) this.$emit("pagechanged", page);
		},
		onClickNextPage() {
			this.$emit("pagechanged", this.currentPage + 1);
		},
		onClickLastPage() {
			this.$emit("pagechanged", this.totalPages);
		},
		isPageActive(page) {
			return this.currentPage === page;
		},
	},
};
</script>
<template>
	<ul
		class="pagination-wrap flex flex-wrap md:flex-nowrap w-full justify-center items-center"
		v-if="totalPages > 1"
	>
		<li class="w-full md:w-auto mb-3 md:mb-0 md:mr-1">
			<SecondaryButton
				@click.native="onClickFirstPage"
				v-if="currentPage > 3"
				text="Inicio"
				class="button p-0 h-10 green-dark mr-1"
				buttonColor="gradient-button-gray"
				aria-label="Go to first page"
			/>
		</li>

		<li class="w-full md:w-auto mb-3 md:mb-0 md:mr-1">
			<SecondaryButton
				@click.native="onClickPreviousPage"
				v-if="!isInFirstPage"
				text="Anterior"
				class="button p-0 h-10 green-dark mr-1"
				buttonColor="gradient-button-gray"
				aria-label="Go to previous page"
			/>
		</li>

		<li
			v-for="page in pages"
			class="w-1/4 md:w-auto mx-auto md:ml-0 mb-3 md:mb-0 md:mr-1"
			:key="`pame_${page.name}`"
		>
			<SecondaryButton
				@click.native="onClickPage(page.name)"
				:text="`${page.name}`"
				class="button p-0 h-10 mr-1"
				:class="[
					isPageActive(page.name) ? 'text-white' : 'green-dark',
					{ disabled: page.isDisabled },
				]"
				:buttonColor="
					isPageActive(page.name)
						? 'gradient-button-green'
						: 'gradient-button-gray'
				"
				:aria-label="`Go to page number ${page.name}`"
			/>
		</li>

		<li class="w-full md:w-auto mb-3 md:mb-0 md:mr-1">
			<SecondaryButton
				@click.native="onClickNextPage"
				v-if="!isInLastPage"
				text="Siguiente"
				class="button rounded-full p-0 h-10 mr-1 green-dark"
				aria-label="Go to next page"
				buttonColor="gradient-button-gray"
			/>
		</li>

		<li class="w-full md:w-auto">
			<SecondaryButton
				@click.native="onClickLastPage"
				v-if="!isInLastPage"
				text="Último"
				class="button rounded-full p-0 h-10 mr-1 green-dark"
				buttonColor="gradient-button-gray"
				aria-label="Go to last page"
			/>
		</li>
	</ul>
</template>

<style lang="scss" scoped>
.pagination-wrap {
	@media (max-width: 767px) {
		max-width: 320px;
		margin: 0 auto;
	}
}
.button {
	min-width: unset;
}

.disabled {
	&:hover {
		animation: none;
	}
	cursor: default;
}
</style>
